<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="add_freelancer()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-form v-model="valid">
          <v-card>
            <v-card-title>Dane podwykonawcy</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>

                  <v-switch
                    v-model="set_password"
                    label="Wprowadź ręcznie hasło"
                  ></v-switch>


                  <v-text-field
                    v-model="user_data.name"
                    label="Nazwa"
                    color="primary"
                  ></v-text-field>

                  <v-text-field
                    v-model="user_data.email"
                    label="Email"
                    color="primary"
                  ></v-text-field>

                  <!-- <v-select
                    v-model="role_id"
                    :items="role"
                    item-text="name"
                    item-value="id"
                    label="Rola"
                    single-line
                  ></v-select> -->

                  <v-autocomplete
                    v-model="role_id"
                    :items="role"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Rola"
                    :rules="$store.state.rules.not_null"
                  ></v-autocomplete>

                  <v-text-field
                    v-model="user_data.number"
                    label="Telefon"
                    color="primary"
                  ></v-text-field>

                  <v-card
                    outlined
                    v-if="set_password"
                  >
                    <v-card-title>Hasło do konta</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="user_data.password"
                            label="Hasło"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="user_data.password_confirmation"
                            label="Powtórz hasło"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>

                  </v-card>
                
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista podwykonawców',
        disabled: false,
        to: '/podwykonawcy',
      },
      {
        text: 'Dodawanie podwykonawcy',
        disabled: true,
        to: '/edit',
      }
    ],

    show1: false,
    set_password: false,
    role_id: 0,
    role: [
      {
        id: 0,
        name: ''
      },
    ],

    user_data: {
      email: '',
      role_id: 0,
      name: '',
      numebr: '',
      password: '',
      password_confirmation: '',
    },
    
    search: ''
  }),
  methods: {
    add_freelancer() {
      this.$store.commit('loader');

      let formData = new FormData();
      formData.append("freelancer", 1);
      formData.append("name", this.user_data.name);
      formData.append("role_id", this.role_id);
      formData.append("email", this.user_data.email);
      formData.append("number", this.user_data.number);
      // Ręczne wprowadzanie hasła
      if(this.set_password){
        formData.append("password", this.user_data.password);
        formData.append("password_confirmation", this.user_data.password_confirmation);
      }
      else {
        formData.append("mail", 1);
      }

      this.$axios({url: this.$store.state.api +'/auth/register', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
        .then(resp => {
          this.$router.push('/podwykonawcy');
          this.$store.commit("snackbar", {
            text: "Dodano podwykonawce",
            color: "primary",
            btn_color: "white"
          });
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          this.$store.commit('loader_off');
        })
    },
  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/auth/role', data: {}, method: 'GET' })
      .then(resp => {
        this.role = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}
</style>